import { fromEvent } from 'rxjs';
import { getConsentLevel, setConsentLevel } from './storage.lib';
import { AvgConsentLevel } from './cookie-settings.config';

(() => {
    const cookieModal = document.getElementById('cookie-modal');
    const acceptButton = document.getElementById('cookie-modal--accept');

    if (! getConsentLevel()) {
        cookieModal?.classList.remove('hidden');
    }

    if (acceptButton) {
        fromEvent(acceptButton, 'click').subscribe(() => {
            setConsentLevel(AvgConsentLevel.MARKETING);
            cookieModal?.classList.add('hidden');
        });
    }
})();
